<template>
	<div class="catalogs-container" :class="{ 'catalogs-container-absolute': absolute }">
		<div class="catalogs-header scroll-customize">
			<div class="catalogs-header-item">
				<p class="catalogs-header-item-title">{{ $t('catalog.by_directions') }}</p>
				<div class="catalogs-header__major-links">
					<button
            v-for="item in majorCategories"
            :key="item.id"
            @click="selectCategoryHandler(item.name, Number(item.id))"
            :class="{ 'active': item.id == chosenCategory }"
            class="catalogs-header__major-links-item"
          >
            {{ item.name }}
          </button>
				</div>
			</div>
      <div
        v-if="allProductTypes"
        class="catalogs-container__separator"
      />
			<div class="catalogs-header-item">
				<p class="catalogs-header-item-title">{{ $t('catalog.by_types') }}</p>
				<div class="catalogs-header__types-links">
					<button
            v-for="item in allProductTypes"
            :key="item.slug"
            @click="selectCategoryHandler(item.name, item.slug)"
            :class="{ 'active': item.slug === chosenCategory }"
            class="catalogs-header__types-links-item"
          >
            {{ item.name }}
          </button>
				</div>
			</div>
		</div>
		<div class="catalogs-box mobile-open">
			<div class="back-box">
				<img @click="setIsCatalogOpen(false)" src="~/assets/images/svg/full-arrow-left.svg" alt="">
				<p>{{ categoriesOpenLabel || '' }}</p>
			</div>
			<div class="catalogs-links">
        <button
          v-for="item in sorted"
          @click="toHash(item.name)"
          :key="item.name"
          class="catalogs-links-item"
          :class="{ active: activeLetterId === `catalog-letter-${item.name}` }"
        >
          {{ item.name }}
        </button>
			</div>
			<div class="catalogs-products-box scroll-customize">
				<div v-for="item in sorted" :key="item.name" :id="`header-letter-${item.name}`" class="catalogs-products observer-item">
					<p class="catalogs-products-title">{{ item.name }}</p>
					<template v-for="(product, i) in item.items">
						<div v-if="product.product_types.length" :key="i" class="catalogs-product-box">
							<NuxtLinkLocale :to="product.slug" class="catalogs-product">
								<NuxtImgC :src="product.image_id" alt="" sizes="28px" />
								{{ product.name }}
							</NuxtLinkLocale>
							<div class="catalogs-product-categories">
								<template v-for="(category, i) in product.product_types">
									<NuxtLinkLocale
                    v-if="category.slug"
                    :key="i"
                    :to="`${product.slug}?typeProduct=${category.id}`"
                  >
                    {{ category.name }}
                  </NuxtLinkLocale>
								</template>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { useCatalogStore } from '#imports';
const { locale } = useI18n()
const props = defineProps({
	absolute: { type: Boolean, default: false },
	activeLetterId: { type: String }
})

const emits = defineEmits<{ close: [] }>()
const catalogStore = useCatalogStore();
const { setIsCatalogOpen, setChosenCategory } = catalogStore
const { sorted, allProductTypes, majorCategories, chosenCategory } = storeToRefs(catalogStore)

const selectCategoryHandler = async (label: string, categoryName: string | number) => {
	setChosenCategory(categoryName)
}
const headerBottomPosition = useState<number>('header-bottom-position')
const getProductTypesLabel = (slug: string) => {
	for (const key in allProductTypes.value) {
		if (allProductTypes.value[key].slug === slug) return allProductTypes.value[key].name
	}
}
if (!chosenCategory.value) setChosenCategory(majorCategories.value[0].id as string | number)




watchEffect(() => {
	catalogStore.setSorted(chosenCategory.value)
})


const categoriesOpenLabel = computed(() => majorCategories.value?.find((a) => Number(a.id) === Number(chosenCategory.value))?.name ?? getProductTypesLabel(String(chosenCategory.value)))

const toHash = async (name: string) => {
	document.querySelector(`#header-letter-${name}`)?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
}

useEventListener('keyup', (e) => {
	if (e.key === 'Escape' || e.key === 'Backspace') emits('close')
})
</script>

<style lang="scss" scoped>
.catalogs-container {
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 24px;
	box-shadow: 0 4px 6px 0 rgba(77, 24, 13, 0.1);
	padding: 32px 40px;
	background-color: white;
}

.catalogs-container-absolute {
	position: absolute;
	width: 100%;
	left: 0;
	top: 100%;
	max-height: calc(100vh - v-bind(headerBottomPosition) * 1px - 16px - 32px);
	max-height: calc(100dvh - v-bind(headerBottomPosition) * 1px - 16px - 32px);
	overflow: hidden;
	transform: translateY(19px);
	display: flex;
	flex-direction: column;

	.catalogs-links {
		top: 0;
	}
}

.catalogs-header {
	display: flex;
  gap: 25px;
	background-color: white;
	overflow-y: auto;
	flex-shrink: 0;
  padding-bottom: 16px;
}

.catalogs-header-item {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.catalogs-header-item-title {
	font-weight: 600;
	font-size: 12px;
	line-height: 110%;
	letter-spacing: 0.09em;
	text-transform: uppercase;
	color: #848398;
}

.catalogs-header__major-links {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.catalogs-header__major-links-item {
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.03rem;
  color: #1D254A;
  cursor: pointer;
  transition: color 0.1s ease, background-color 0.1s ease;
  border-radius: 14px;
  padding: 6px 8px;

  &:hover {
    color: #DA4727;
  }

  &.active {
    color: #ffffff;
    background-color: #DA4727;
  }
}

.catalogs-header__types-links {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
}

.catalogs-header__types-links-item {
  color: #494F6D;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  letter-spacing: -0.03rem;
  border-radius: 120px;
  padding: 7px 10px;
  background-color: #F0F0F0;
  cursor: pointer;
  transition: color 0.1s ease, background-color 0.1s ease;

  &:hover {
    color: #da4727;
    box-shadow: 0px 4px 12px 0px rgba(77, 24, 13, 0.5);
  }

  &.active {
    color: #ffffff;
    background-color: #DA4727;
  }
}

.catalogs-container__separator {
  content: '';
  width: 2px;
  min-height: 100%;
  background-color: #E6E6E6;
}

.catalogs-box {
	display: grid;
	grid-template-columns: minmax(21px, auto) 1fr;
	gap: 20px;
	margin-top: 8px;
	position: relative;
	background-color: white;
	min-height: 0;
}

.catalogs-links {
	top: calc(v-bind(headerBottomPosition) * 1px + 8px);
	left: 0;
	position: sticky;
	display: flex;
	flex-direction: column;
	font-weight: 600;
	font-size: 16px;
	line-height: 1;
	letter-spacing: -0.03em;
	color: #1d254a;
	text-transform: uppercase;
	gap: 4px;
	height: fit-content;

	.catalogs-links-item {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 21px;
		height: 21px;
		cursor: pointer;
		transition: color, background, .3s ease;
		border-radius: 6px;

		&:hover,
		&.active {
			background: rgba(218, 72, 39, 0.25);
			color: #da4727;
		}
	}
}

.catalogs-products-box {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 32px 36px;
	border-radius: 24px;
	background: #f0f0f0;
	overflow: hidden auto;
}

.catalogs-products {
	display: grid;
	grid-template-columns: repeat(auto-fill, 142px);
	gap: 10px 32px;
	padding-bottom: 20px;
	border-bottom: 1px solid #dadada;

	&:last-child {
		border-bottom: 0;
		padding-bottom: 0;
	}
}

.catalogs-products-title {
	grid-column: 1/-1;
	font-weight: 800;
	font-size: 36px;
	line-height: 100%;
	letter-spacing: -0.03em;
	color: #1d254a;
	text-transform: uppercase;
}

.catalogs-product-box {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.catalogs-product {
	display: flex;
	align-items: center;
	gap: 7px;
	padding: 6px;
	border-radius: 40px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
	background: #fff;
	font-weight: 700;
	font-size: 13px;
	letter-spacing: -0.03em;
	color: #283647;
	hyphens: auto;
	transition: color, box-shadow, .3s ease;

	&:hover {
		color: #da4727;
		box-shadow: 0px 4px 12px 0px rgba(77, 24, 13, 0.5);
	}

	img {
		width: 28px;
		height: 28px;
		border-radius: 100%;
		object-fit: contain;
		object-position: center;
	}
}

.catalogs-product-categories {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-weight: 400;
	font-size: 12px;
	line-height: 140%;
	letter-spacing: -0.03em;
	color: #949494;

	a {
		transition: color .3s ease;

		&:hover {
			text-decoration: underline;
			text-decoration-skip-ink: none;
			color: #da4727;
		}
	}
}

.back-box {
	display: none;

	img {
		cursor: pointer;
	}
}

@media (max-width:1023px) {
	.catalogs-container {
		border: none;
		padding: 0;
		border-radius: 12px;
		box-shadow: 0 4px 6px 0 rgba(77, 24, 13, 0.1);
    background-color: var(--background-base);
  }

	.catalogs-container-absolute {
		height: 100dvh;
		border: none;
		border-radius: 0;
		padding: 0;
		box-shadow: 0 4px 6px 0 rgba(77, 24, 13, 0.1);
		width: 100%;
    top: 85%;
		transform: translateY(11px);
	}

	.catalogs-header {
		border-radius: 0;
		gap: 16px;
		padding: 14px 0 6px;
		flex-direction: column;
		flex-shrink: 1;
    background-color: var(--background-base);

    .catalogs-header-item:first-child {
      display: none;
    }

    .catalogs-header-item:last-child {

      .catalogs-header-item-title {
        display: none;
      }
    }

    .catalogs-container__separator {
      display: none;
    }

		&.mobile-open {
			display: flex;
		}


    .catalogs-header__types-links-item {
      border-radius: 10px;
      padding: 11px 12px;
      &:not(.active) {
        background-color: rgba(9, 6, 48, 0.1);
      }
    }
	}

	.catalogs-header-item {
		gap: 12px;

		&:last-child {

			.catalogs-header-item-links a:last-child {
				border: none;
				padding-bottom: 0;
			}
		}
	}

	.catalogs-header-item-title {
		font-size: 10px;
	}

	.catalogs-header-item-links {
		gap: 0;
		flex-direction: column;
		font-weight: 600;
		font-size: 20px;

		.catalogs-header-item-links-item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 13px 0;
			border-bottom: 1px solid #e6e6e6;

			&:first-child {
				padding-top: 0;
			}

			&.active,
			&:hover {
				border-color: #e6e6e6;
				color: #1d254a;
			}

			&::after {
				content: url('assets/images/svg/arrow-right.svg');
				display: block;
				width: 16px;
				height: 14px;
				filter: invert(41%) sepia(37%) saturate(4564%) hue-rotate(345deg) brightness(87%) contrast(95%);
			}

			&:last-child {
				border: none;
				padding-bottom: 0;
			}
		}
	}

	.catalogs-box {
		display: none;
		grid-template-columns: minmax(27px, auto) 1fr;
		grid-template-rows: auto 1fr;
		flex: 1;
		gap: 16px 0;
		margin-top: 0;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 12px;
		padding-top: 20px;

		&.mobile-open {
			display: grid;
		}
	}

	.catalogs-links {
		font-size: 13px;
		line-height: 90%;
		gap: 4px;
		padding: 0 3px 0 3px;
	}

	.catalogs-products-box {
		gap: 14px;
		padding: 16px 11px 12px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 12px;
		margin: 0 20px 20px 0;
	}

	.catalogs-products {
		gap: 16px 8px;
		padding-bottom: 12px;

		&:last-child {
			border-bottom: 0;
			padding-bottom: 0;
		}
	}

	.catalogs-products-title {
		font-size: 24px;
		margin-bottom: -6px;
	}

	.catalogs-product-box {
		gap: 9px;
	}

	.back-box {
		display: flex;
		gap: 3px;
		align-items: center;
		font-weight: 600;
		font-size: 20px;
		line-height: 80%;
		letter-spacing: -0.03em;
		color: #1d254a;
		grid-column: 1/-1;
		margin-left: 16px;

		img {
			filter: invert(41%) sepia(37%) saturate(4564%) hue-rotate(345deg) brightness(87%) contrast(95%);
			width: 24px;
			height: 24px;
		}
	}

}
</style>
